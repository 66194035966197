import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = ["input"]

    connect() {
        const model = this.element.dataset.model;

        new TomSelect(this.inputTarget, {
            create: false,
            valueField: 'id',
            labelField: 'text',
            searchField: 'text',
            load: (query, callback) => {
                if (!query.length) return callback();
                fetch(`/backend/search?term=${encodeURIComponent(query)}&model=${model}`)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                    }).catch(() => {
                    callback();
                });
            }
        });
    }
}